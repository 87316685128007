@import "bootstrap/dist/css/bootstrap.min.css";
@import "@fontsource/ubuntu/300.css";
@import "@fontsource/ubuntu/400.css";
@import "@fontsource/ubuntu/500.css";
@import "@fontsource/ubuntu/700.css";


:root {
  --normal-text-color: #5b5b5b;
}

:root {
  --red-color: #a51c1c;
  --blue-color: #f3f9ff;
  --bg-solid: var(--bs-white);
  --color-solid: var(--bs-black);
}

.App {
  --bs-body-font-size: 1.25rem;
  --bs-border-radius: 1.1em;
}




html body {
  font-family: "Ubuntu";
  font-weight: 400;
  font-size: large;
  color: var(--normal-text-color);
}

body {
  /* color: rgb(82, 82, 102); */
}


/** dark theme */
html:has([data-bs-theme="dark"]),
body:has([data-bs-theme="dark"]),
div[data-bs-theme="dark"] {

  --red-color: #ff4a4a;
  --blue-color: #004eff1a;
  --bg-solid: var(--bs-dark);
  --color-solid: var(--bs-body-color);
  background-color: var(--bs-gray-dark);
}

html:has([data-bs-theme="dark"]),
body:has([data-bs-theme="dark"]) {

  b {
    font-weight: 500;
    color: #6dbeeb;
  }
}

html:has([data-bs-theme="dark"]),
body:has([data-bs-theme="dark"]) {

  h1,
  h2,
  h3,
  h4,
  h5,
  a,
  div,
  p {
    color: var(--bs-body-color);
  }
}

html:has([data-bs-theme="dark"]),
body:has([data-bs-theme="dark"]) {
  .shadow-box {
    box-shadow: 0 0 16px 0 rgba(255, 255, 255, 0.1);
  }

  .toast-style {
    background-color: var(--bs-black);
  }

  .footer-text {
    color: var(--bs-tertiary-color)
  }
}

html:has([data-bs-theme="dark"]),
body:has([data-bs-theme="dark"]),
div[data-bs-theme="dark"] {

  .words-list-item-component,
  .block-with-definition {
    border: #dee2e633 1px solid;
  }

  .words-list-item-component {
    background-color: #353a40;
    background-color: var(--bs-dark);
    color: var(--bs-body-color);
  }

  .navbar-themed,
  .bg-solid {
    background-color: var(--bs-dark);
  }
}


/* dark theme END*/

.br {
  border-radius: var(--bs-border-radius);
}


.accordion {
  border-radius: var(--bs-border-radius);
  border-color: var(--bs-accordion-border-color);
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--normal-text-color);
}


.bg-blue {
  background-color: var(--blue-color);
}

/* .bg-light-light {
  background-color: #ffffff00;
  box-shadow: inset 0 0 10px #e3e3e3;
} */

.shadow-box {
  box-shadow: 0 0 16px 0 rgb(0 82 134 / 10%);
}

.p-accordion-header {
  padding: 2px 12px;
}

.delete-btn {
  /* padding: 0px 5px; */
  margin: 2px;
  cursor: pointer;
  color: var(--red-color);
}

.delete-all-btn {
  color: var(--red-color);
}

.edit-btn {
  color: var(--color-solid);
}

.save-btn {
  color: var(--bs-green);
}

.red {
  color: var(--red-color);
}

.gray {
  color: var(--normal-text-color);
}

.gradient-border-button {
  color: var(--bs-green);
}



.edit-input {
  border: 0;
  height: 1em;
  outline: dotted 1px rgb(220, 229, 232);
}

.edit-btn {
  /* padding: 0px 5px; */
  margin: 2px;
  cursor: pointer;
  color: var(--bs-blue);
}

.save-btn {
  /* padding: 0px 5px; */
  margin: 2px;
  cursor: pointer;
  color: var(--bs-green);
}

.add-to-dictionary-btn {
  /* padding: 0px 5px; */
  margin: 2px;
  cursor: pointer;
  color: var(--bs-green);
}

.add-all-to-dictionary-btn {
  /* padding: 0px 5px; */
  margin: 2px 10px;
  cursor: pointer;
  color: var(--bs-blue);
}

.remove-from-dictionary-btn {
  /* padding: 0px 5px; */
  margin: 2px;
  cursor: pointer;
  color: var(--red-color);
  ;
}

.words-list-item-component {
  padding: 0px 5px;
  /* margin: 5px; */
  margin-right: 10px;
  margin-bottom: 6px;
  float: left;
  text-decoration: none;
  /* background-color: #fff; */
  border: #dee2e6 1px solid;
  border-radius: 10px;
  padding-left: 12px;
}

.block-with-definition {
  padding: 15px;
  margin: 5px;
  border: #dee2e6 1px solid;
  border-radius: 10px;
}



.fixed {
  position: fixed;
  right: 0;
}

.new-line {
  white-space: pre-line;
}

@media only screen and (max-width: 576px) {
  .fixed {
    position: relative;
    right: 0;
  }

  .reversed {
    flex-direction: column-reverse;
  }

}

.wrapper {
  display: flex;
  min-height: 100vh;
  /* Ensure the wrapper takes at least the full viewport height */
  flex-direction: column;
}

.content {
  flex: 1;
  /* Flex grow to take up remaining space */
}

.footer {
  margin-top: auto;
  /* Push footer to the bottom */
}

.footer-text {
  color: var(--bs-tertiary-color)
}

.box {
  position: relative;
  transform-style: preserve-3d;
  border-radius: 0px;
}

.box::before {
  content: "";
  position: absolute;
  inset: 0px;
  background: conic-gradient(from 90deg at 40% -25%, #ffd700, #f79d03, #ee6907, #e6390a, #de0d0d, #d61039, #cf1261, #c71585, #cf1261, #d61039, #de0d0d, #ee6907, #f79d03, #ffd700, #ffd700, #ffd700);
  filter: blur(8px);
  transform: translate3d(0px, 0px, -1px);
  border-radius: inherit;
  pointer-events: none;
}

.navbar:after {
  background-image: linear-gradient(to left, #ffca80, #ff59ba 50%, #3df);
  height: 10px;
  width: 100%;
}

.navbar {
  background-color: white;
}

.blob {
  background-color: wheat;
  background:
    radial-gradient(110.88% 79.69% at 47.77% 151.82%, #ffec45 0, #ffec454d 54.92%, #ffec4500 96.11%),
    radial-gradient(50% 68.23% at 98.21% 96.61%, #41d1b7 0, #41d1b759 49.27%, #41d1b700 100%),
    radial-gradient(83.71% 75.52% at -10.04% 2.86%, #2e1ca5 0, #8172da42 56.87%, #8172da00 100%),
    linear-gradient(180deg, #04ecd5, #58ccdc00), linear-gradient(15.23deg, #fe3ecf 21.4%, #f550 80.87%, #f2f5fa);
  background-blend-mode: multiply, screen, saturation, lighten, color, luminosity;
  filter: blur(90px);
  height: 77%;
  opacity: .3;
  position: fixed;
  top: 20px;
  width: 100%;
  z-index: -1;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.words-list-item-component {
  background-color: var(--bg-solid)
}

.user-info-nav {}

.avatar-sm {
  height: 40px;
  border-radius: 100px;
}

.avatar-big {
  height: 100px;
  border-radius: 100px;
}

.dropdown-user-info {
  width: 100%;
}

.theme-button {
  margin-right: 20px;
}



.bg-solid {
  background-color: var(--bg-solid);
  border: 1px solid var(--bs-border-color);
  color: var(--color-solid);
}

.signin-page-block {
  border-radius: var(--bs-border-radius);
  padding: var(--bs-gutter-x);
}

.textarea {
 border-width: 3px;
 border-style: dotted;
}

.textarea::-webkit-scrollbar {
  width: 8px;
}

.textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.textarea::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.textarea::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.circle-image-big {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
}

.circle-image-big img {
  display: block;
  width: 100%;
  height: auto;
}


.circle-image-sm {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  float: left;
}

.circle-image-sm img {
  display: block;
  width: 100%;
  height: auto;
}


.login-btn {
  background-color: white;
  color: #212936;
  border-radius: 0.5rem;
  border-color: rgb(209 213 219);
  border-width: 1px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -2px;
  margin: 10px;
}

.login-btn:hover {
  background-color: rgb(229, 231, 235);
  border-color: rgb(209 213 219);
  color: #212936;

}

.empty {
  background: #b9b9b9;
}

.nice-button {
  padding: 5px 20px;
  border: none;
  background-color: #33ccff;
  text-align: center;
  transition: all .1s ease-in;
  font-weight: 700;
  font-size: 20px;
  border-radius: 8px;
  box-sizing: content-box;
  line-height: inherit;
  color: #ffffff;
  text-decoration: none;
  box-shadow: 0px 4px 16px 0px rgba(51, 204, 255, 0.5);
}

.nice-button:hover {
  background-color: #12aee1;
}

.gradient-border-button {
  background-color: transparent;
  border: 3px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(45deg, #ff003c, #c648c8);
  background-size: 19px 19px, calc(100% - 38px) 3px, 19px 19px, 3px calc(100% - 38px);
  background-repeat: no-repeat;
  color: #000000;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, darkblue, darkorchid) border-box;
  border-radius: 50em;
  border: 4px solid transparent;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  color: darkblue;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.glow-on-hover {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #000000;
  background: #ffffff;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  border: 1px solid #0000002d;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  color: #000000;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff;
  left: 0;
  top: 0;
  border-radius: 10px;
  color: #000000
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  border: none;
  box-shadow: none;
}

.pointer {
  cursor: pointer;
}
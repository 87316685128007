/* LandingMainComponent.module.css */

@import "bootstrap/dist/css/bootstrap.min.css";
@import "@fontsource/nunito/200.css";
@import "@fontsource/nunito/300.css";
@import "@fontsource/nunito/400.css";
@import "@fontsource/nunito/500.css";
@import "@fontsource/nunito/600.css";
@import "@fontsource/nunito/700.css";
@import "@fontsource/nunito/800.css";
@import "@fontsource/nunito/900.css";
@import "@fontsource/nunito";

.landing * {
  font-family: "Nunito" !important;
  font-weight: 700;
}

.main {
  flex: 1 1;
}

.nunito {
  font-family: "Nunito" !important;
  font-weight: 700;
}

.container {
  max-width: 960px;
}

.black {
  color: black;
}

.shadow-box {
  box-shadow: 0 0 16px 0 rgb(0 82 134 / 10%);
}

.site-header {
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.site-header a {
  color: #8e8e8e;
  transition: color .15s ease-in-out;
}

.site-header a:hover {
  text-decoration: none;
}

.product-device {
  position: absolute;
  right: 10%;
  bottom: -10%;
  width: 300px;
  height: 540px;
  background-color: #bdbdbd;
  border-radius: 31px;
  transform: rotate(20deg);
}

.product-device::before {
  position: absolute;
  top: 2%;
  right: 10px;
  bottom: 5%;
  left: 10px;
  content: "";
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  background-size: cover;
  background-image: url("app/assets/screenshot.webp");
}

.product-device-2 {
  top: +20%;
  right: auto;
  bottom: 0;
  left: 5%;
  width: 450px;
  height: 251px;
  background-color: #e5e5e5;
  transform: rotate(-20deg);
  z-index: 1;
  border-radius: 10px;
}

.landing {
  scroll-timeline-name: --squareTimeline;
}

.animate {
  animation-name: rotateAnimation;
  animation-duration: 1ms;
  animation-direction: alternate;
  animation-timeline: --squareTimeline;
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.product-device-2::before {
  background-size: cover;
  top: 3%;
  right: 1%;
  bottom: 3%;
  left: 1%;
  border-radius: 0;
  background-image: url("app/assets/desktop.webp");
}

.flex-equal>* {
  flex: 1;
}

@media (min-width: 768px) {
  .flex-md-equal>* {
    flex: 1;
  }
}

.nice-button {
  padding: 5px 20px;
  border: none;
  background-color: #33ccff;
  text-align: center;
  transition: all .1s ease-in;
  font-weight: 700;
  font-size: 20px;
  border-radius: 8px;
  box-sizing: content-box;
  line-height: inherit;
  color: #ffffff;
  text-decoration: none;
  box-shadow: 0px 4px 16px 0px rgba(51, 204, 255, 0.5);
}

.nice-button:hover {
  background-color: #12aee1;
}

.logo-color {
  color: #fe3ecf;
  background-image: -webkit-linear-gradient(45deg, #fe3ecf 0%, #be46d8 14%, #793feb 29%, #0a52f6 43%, #0490f3 57%, #06cfed 71%, #09ede0 86%);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

.hero-title {
  font-weight: 900;
  z-index: 100;
}

.hero-text {
  font-weight: 900;
  z-index: 100;
}

.text-button-big {
  font-weight: 900;
  font-size: 1.6em;
  z-index: 100;
}

.text-button-big:hover {
  font-weight: 900;
}

.backdrop {
  backdrop-filter: blur(2px);
}

.pattern-bg {
  background-image: linear-gradient(#dedefb40 3px, transparent 3px), linear-gradient(90deg, #e2e4ff26 3px, transparent 3px), linear-gradient(#f1f1f76e 1.5px, transparent 1.5px), linear-gradient(90deg, #f4f5ff00 1.5px, #ffffff 1.5px);
  background-size: 75px 75px, 75px 75px, 15px 15px, 15px 15px;
  background-position: -3px -3px, -3px -3px, -1.5px -1.5px, -1.5px -1.5px;
}

.blob {
  position: absolute;
  background-color: wheat;
  top: 20px;
  width: 50%;
  height: 5%;
  background: radial-gradient(110.88% 79.69% at 47.77% 151.82%, #FFEC45 0%, rgb(255 229 0) 54.92%, rgba(255, 236, 69, 0) 96.11%), radial-gradient(50% 68.23% at 98.21% 96.61%, #41D1B7 0%, rgb(65 209 183) 49.27%, rgb(65 209 183 / 0%) 100%), radial-gradient(83.71% 75.52% at -10.04% 2.86%, #8263DB 0%, rgba(129, 114, 218, 0.26) 56.87%, rgba(129, 114, 218, 0) 100%), linear-gradient(180deg, #58CCDC 0%, rgb(88 204 220) 100%), linear-gradient(81.23deg, #ff55e5 21.4%, rgb(255 85 85 / 35%) 84.87%, #F2F5FA);
  background-blend-mode: multiply, screen, saturation, lighten, color, luminosity;
  filter: blur(89px);
  opacity: 0.2;
}

.landing-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}